import { inject, Injectable } from "@angular/core";
import { UpgradeModule } from "@angular/upgrade/static";

@Injectable({
    providedIn: "root"
})
export class RedirectService {
    private readonly redirectUrlKey = "redirectUrl";
    private readonly upgrade = inject(UpgradeModule);
    private readonly sessionService = this.upgrade.$injector.get("SessionService");
    private readonly localStorageFactory = this.upgrade.$injector.get("LocalStorageFactory");

    public isValidRedirectUrl(redirectUrl: string): boolean {
        const invalidUrls = [
            "/unauthorized",
            "/access-denied",
            "/auth-error",
            "/callback",
            "/account/login",
            "/account/register",
            "/account/logout",
            "/?email="
        ];

        const isValidUrl = !invalidUrls.some((url) => redirectUrl.startsWith(url) || redirectUrl === url);

        return isValidUrl;
    }

    public async setRedirectUrl(redirectUrl: string): Promise<void> {
        if (this.isValidRedirectUrl(redirectUrl)) {
            this.localStorageFactory.set(this.redirectUrlKey, redirectUrl);
            console.log("Setting redirect url to ", redirectUrl);
        }
    }

    public getRedirectUrl(remove = false): string {
        let redirectUrl = null;

        if (this.sessionService.isSysAdmin()) redirectUrl = "/dashboard/messaging";
        else if (this.sessionService.userInRole("Administrator")) redirectUrl = "/account/dashboard";
        else if (this.sessionService.userInRole("User")) redirectUrl = "/intake";

        redirectUrl = this.localStorageFactory.get(this.redirectUrlKey);

        if (remove) this.localStorageFactory.remove(this.redirectUrlKey);

        return redirectUrl;
    }
}
