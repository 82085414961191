<div>
    <div class="modal-header">
        <button
            type="button"
            class="close"
            (click)="cancel()">
            &times;
        </button>
        <h2 class="modal-title">Update Profile</h2>
    </div>
    <div class="row">
        <div
            class="col-sm-3 pr-0"
            style="margin-top: -1px">
            <div class="inbox">
                <div class="col-sm-12 user-menu">
                    <ul class="event-type-list">
                        <li
                            class="title alerts-title"
                            style="cursor: default">
                            Profile Options
                        </li>
                        <li
                            [class.alerts-menu-active]="currentTab === 'editProfile'"
                            (click)="currentTab = 'editProfile'">
                            Edit Profile
                        </li>
                        @if ($any(user)?.organizations.length) {
                            <li
                                ng-if="$ctrl.userProfile.organizations.length > 0"
                                [class.alerts-menu-active]="currentTab === 'emailNotifications'"
                                (click)="currentTab = 'emailNotifications'">
                                Email Notifications
                            </li>
                        }
                        <li
                            *kno2FeatureToggle="['UserPreferences']"
                            [class.alerts-menu-active]="currentTab === 'userPreferences'"
                            (click)="currentTab = 'userPreferences'">
                            User Preferences
                        </li>
                        @if ($any(user)?.authorizedApps?.length) {
                            <li
                                [class.alerts-menu-active]="currentTab === 'authorizedApplications'"
                                (click)="currentTab = 'authorizedApplications'">
                                My Applications
                            </li>
                        }
                        @if ($any(user)?.externalLoginTokens?.length || hasCacheIntegrationData) {
                            <li
                                [class.alerts-menu-active]="currentTab === 'integrations'"
                                (click)="currentTab = 'integrations'">
                                Integrations
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </div>
        <div class="modal-body col-sm-9 mt-4">
            @if (error) {
                <kno2-notification-alert
                    type="danger"
                    [title]="error"
                    (dismiss)="error = null"></kno2-notification-alert>
            }
            @if (form) {
                <kno2-profile-edit
                    [hidden]="currentTab !== 'editProfile'"
                    [parentForm]="form"
                    [mfaRequired]="mfaRequired" />
                <kno2-profile-email-notifications
                    [hidden]="currentTab !== 'emailNotifications'"
                    [preferencesForm]="form.get('userPreferences')"
                    [isAdministrator]="user.isAdministrator" />
                <kno2-profile-user-preferences
                    [hidden]="currentTab !== 'userPreferences'"
                    [preferencesForm]="form.get('userPreferences')" />
                <kno2-profile-authorized-applications [hidden]="currentTab !== 'authorizedApplications'" />
                <kno2-profile-integrations
                    [hidden]="currentTab !== 'integrations'"
                    (onRevokeToken)="loadUser()" />
            } @else {
                <kno2-loading-spinner />
            }
        </div>
    </div>

    <div class="modal-footer mt-0">
        <button
            type="button"
            class="btn btn-default"
            (click)="cancel()">
            Cancel
        </button>
        <button
            id="SaveChanges"
            class="btn btn-primary"
            data-style="zoom-in"
            (click)="save()"
            [disabled]="isSubmitting">
            @if (isSubmitting) {
                <span class="spinner-border spinner-border-sm"></span>
            }
            Save Changes
        </button>
    </div>
</div>
