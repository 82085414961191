import { Component, input } from "@angular/core";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FeatureToggleDirective, PopoverDirective } from "@kno2/shared/directives";

@Component({
    selector: "kno2-profile-user-preferences",
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, PopoverDirective, FeatureToggleDirective],
    templateUrl: "./profile-user-preferences.component.html"
})
export class ProfileUserPreferencesComponent {
    public preferencesForm = input.required<FormGroup>();
}
