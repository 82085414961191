import { Component, inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { RedirectService } from "@kno2/shared/services";
import { AuthService } from "../../core/services/auth.service";
import { WINDOW_TOKEN } from "../../core/tokens/window.token";

@Component({
    selector: "kno2-callback",
    standalone: true,
    template: ""
})
export class CallbackComponent implements OnInit {
    private readonly window = inject(WINDOW_TOKEN);
    private readonly router = inject(Router);
    private readonly authService = inject(AuthService);
    private readonly redirectService = inject(RedirectService);
    private readonly upgrade = inject(UpgradeModule);
    private readonly sessionService = this.upgrade.$injector.get("SessionService");
    private readonly featureService = this.upgrade.$injector.get("FeatureService");

    public async ngOnInit() {
        const getErrorDescriptionFromUrl = () => {
            return this.window.location.hash
                .substring(1)
                .split("&")
                .map((item) => item.split("="))
                .find((parts) => parts[0] === "error_description")?.[1];
        };

        const urlErrorDescription = getErrorDescriptionFromUrl();

        this.window.location.hash = "";
        try {
            await this.authService.renewTokens();
        } catch (err) {
            const errorDescription = err.description === "[object Object]" && urlErrorDescription ? decodeURIComponent(urlErrorDescription) : err.description;

            await this.router.navigate(["/auth-error"], {
                state: {
                    code: err.code,
                    description: errorDescription
                }
            });
        }
        await this.complete();
    }

    public async complete() {
        await this.sessionService.refreshProfile();
        await this.featureService.load({ reload: true });

        if (this.sessionService.isSysAdmin()) {
            this.window.location.href = "/admin/messaging/dashboard";
            return;
        }

        let redirectUrl = this.redirectService.getRedirectUrl();

        this.router.navigateByUrl(redirectUrl);
    }
}
