import { Component, inject, OnInit } from "@angular/core";
import { AuthService } from "@kno2/core/services";
import { WINDOW_TOKEN } from "@kno2/core/tokens";
import { FormatAuthErrorPipe } from "./format-auth-error.pipe";

@Component({
    selector: "kno2-auth-error",
    imports: [FormatAuthErrorPipe],
    standalone: true,
    template: `
        <div style="height: 100vh; background-color: #fff; padding-top: 100px">
            <h1 class="text-center">ERROR: {{ error?.code | formatAuthError }}</h1>
            <h2 class="text-center">
                <p>{{ error.description }}</p>
                @if (hasAccessDeniedError) {
                    <p>You will be redirected to the login screen in {{ seconds }} seconds.</p>
                }
            </h2>
        </div>
    `
})
export class AuthErrorComponent implements OnInit {
    private readonly window = inject(WINDOW_TOKEN);
    private readonly authService = inject(AuthService);
    protected error: { code: string; description: string };
    protected seconds = 6;
    protected hasAccessDeniedError = false;

    public ngOnInit(): void {
        const state = this.window.history.state;
        this.error = state as { code: string; description: string };
        this.hasAccessDeniedError = this.error.code === "access_denied";

        if (this.hasAccessDeniedError) this.countdown();
    }

    public countdown(): void {
        setInterval(() => {
            this.seconds--;
            if (this.seconds === 0) this.authService.logout();
        }, 1000);
    }
}
