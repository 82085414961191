import { find } from "lodash";
import templateUrl from "./identityproviders.component.html";

class IdentityProvidersController {
    constructor(
        IdentityProvidersService,
        NotificationService,
        Auth0Service,
        SessionService,
        IntegrationsService,
        LocalStorageFactory,
        $uibModal,
        $confirm,
        $scope
    ) {
        this.identityProvidersService = IdentityProvidersService;
        this.notificationService = NotificationService;
        this.auth0Service = Auth0Service;
        this.sessionService = SessionService;
        this.integrationsService = IntegrationsService;
        this.localStorageFactory = LocalStorageFactory;
        this.$uibModal = $uibModal;
        this.$confirm = $confirm;
        this.$scope = $scope;
    }

    $onInit() {
        this.loadData();
    }

    loadData() {
        const promises = [this.getIdentityProvider()];

        if (!this.typesResponse) {
            promises.push(this.getIdentityProviderTypes());
        } else {
            promises.push(Promise.resolve(this.typesResponse));
        }

        if (!this.integrationsResponse) {
            promises.push(this.getIntegrations());
        } else {
            promises.push(Promise.resolve(this.integrationsResponse));
        }

        Promise.all(promises).then((responses) => {
            const identityProviderResponse = responses[0];
            this.typesResponse = responses[1];
            this.integrationsResponse = responses[2];

            const idp = identityProviderResponse.data.id === 0 ? null : identityProviderResponse.data;
            if (idp && idp.type) {
                const type = find(this.typesResponse.data, (x) => x.type === idp.type);
                for (let idx = 0; idx < type.properties.length; idx++) {
                    const prop = type.properties[idx];
                    if (prop.isTagsInput) {
                        idp.values[prop.name] = idp.values[prop.name].split(",").map((x) => {
                            return { text: x };
                        });
                    }
                }
            }

            if (idp && idp.name === "PointClickCare") {
                this.externalSignInLabel = "Sign in with PointClickCare";
                this.setupIntegrationLabel = "Setup PointClickCare Integration";
                if (idp.values && idp.values.AllowedUsers && idp.values.AllowedUsers[0]) {
                    this.setupUsername = idp.values.AllowedUsers[0].text;
                }

                const pccIntegration = find(this.integrationsResponse, { name: "PointClickCare" });
                this.hasExistingPccIntegration = !!pccIntegration;
            }

            this.types = this.typesResponse.data;
            this.identityProvider = idp;
            this.$scope.$apply();
        });
    }

    getIdentityProvider() {
        return this.identityProvidersService.get();
    }

    getIdentityProviderTypes() {
        return this.identityProvidersService.getTypes();
    }

    getIntegrations() {
        return this.integrationsService.list();
    }

    upsertIdentityProvider() {
        const identityProvider = this.identityProvider || {};
        const modal = this.$uibModal.open({
            component: "k2IdentityProvidersUpsertModal",
            resolve: {
                availableTypes: () => this.types,
                identityProvider: () => identityProvider
            }
        });

        modal.result
            .then((result) => {
                this.loadData();
                this.notificationService.success(`The Identity Provider was saved successfully.`);
            })
            .catch(angular.noop);
    }

    delete() {
        const $this = this;
        const id = this.identityProvider.id;

        const confirm = this.$confirm.open({
            title: "Delete Identity Provider",
            bodyText: "Are you sure you want to delete the Identity Provider?"
        });

        confirm.result.then(function (ok) {
            if (ok) {
                $this.identityProvidersService
                    .delete(id)
                    .then((res) => {
                        $this.loadData();
                        $this.notificationService.success(`The Identity Provider was deleted successfully.`);
                    })
                    .catch((err) => $this.notificationService.error(err.data.message));
            }
        });
    }

    showAdditionalSetup() {
        const isPcc = this.identityProvider.name === "PointClickCare";
        const hasOrgUuid = !!this.identityProvider.values?.OrgUuid;
        return isPcc && !hasOrgUuid;
    }

    externalSignIn() {
        const profile = this.sessionService.getProfile();
        this.localStorageFactory.set("redirectUrl", "/settings/identityproviders");
        this.identityProvidersService.setCache(profile.organizationId, this.identityProvider.values.AdminSetupUser).then(() => {
            this.auth0Service.login({ connection: "pcc" });
        });
    }

    showSetupIntegration() {
        const isPcc = this.identityProvider.name === "PointClickCare";
        const hasOrgUuid = !!this.identityProvider.values?.OrgUuid;
        return isPcc && hasOrgUuid && !this.hasExistingPccIntegration;
    }

    setupIntegration() {
        const integration = {
            type: "Inofile.Kno2.Integration.Integrations.PointClickCare.PointClickCareIntegration",
            capabilities: {
                documentTypeLookup: true,
                patientLookup: true,
                patientDetailsLookup: true,
                haveExternalLogin: true,
                documentUpload: true,
                facilityLookup: true,
                cacheIntegrationData: true
            },
            values: {
                IncludeCurrentPatients: true,
                IncludeNewPatients: false,
                OrgUuid: this.identityProvider.values.OrgUuid
            }
        };
        this.integrationsService.upsert(integration).then(() => {
            this.getIntegrations().then((res) => {
                this.notificationService.success(`Your PointClickCare integration was created successfully.`);
                const pccIntegration = find(res, { name: "PointClickCare" });
                this.hasExistingPccIntegration = !!pccIntegration;
            });
        });
    }
}

IdentityProvidersController.$inject = [
    "IdentityProvidersService",
    "NotificationService",
    "Auth0Service",
    "SessionService",
    "IntegrationsService",
    "LocalStorageFactory",
    "$uibModal",
    "$confirm",
    "$scope"
];

export const identityProvidersComponent = {
    templateUrl: templateUrl,
    controller: IdentityProvidersController
};
