import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { UpgradeModule } from "@angular/upgrade/static";
import { Observable } from "rxjs";
import { AuthService } from "../services";

export const apiInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const upgrade = inject(UpgradeModule);
    const authService = inject(AuthService);
    const appConfig = upgrade.$injector.get("appConfig");

    if (request.url.startsWith("/api")) {
        request = request.clone({ url: appConfig.baseApiUrl + request.url });
    }

    return next(request);
};
