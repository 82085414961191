import { Component, inject, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "kno2-confirmation-dialog",
    standalone: true,
    imports: [],
    templateUrl: "./confirmation-dialog.component.html"
})
export class ConfirmationDialogComponent {
    private readonly activeModal = inject(NgbActiveModal);

    @Input() public bodyText = "Are you sure?";
    @Input() public okText = "OK";
    @Input() public cancelText = "Cancel";

    public confirm(): void {
        this.activeModal.close(true);
    }

    public cancel(): void {
        this.activeModal.dismiss();
    }
}
