import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { WINDOW_TOKEN } from "../tokens";

export const rootGuard: CanActivateFn = async (route, state) => {
    const router = inject(Router);
    const upgrade = inject(UpgradeModule);
    const window = inject(WINDOW_TOKEN);
    const redirectService = upgrade.$injector.get("RedirectService");
    const sessionService = upgrade.$injector.get("SessionService");

    const baseHref = "/";
    if (state.url === baseHref && sessionService.isSysAdmin()) {
        window.location.href = "/admin/dashboard/messaging";
    }

    if (state.url === baseHref) {
        return router.parseUrl(redirectService.redirect());
    }

    return true;
};
