<div class="form-group row">
    <div class="col-sm-8 col-sm-offset-1">
        <div class="form-check">
            <input
                name="enabled2FA"
                [formControl]="preferencesForm().get('emailSystemNotifications')"
                class="form-check-input mr-2"
                type="checkbox" />
            <label class="form-check-label">
                Notifications
                <span
                    class="ml-2"
                    kno2Popover
                    [attr.data-content]="emailNotificationsTooltip">
                    <i class="fa fa-info-circle box-info"></i>
                </span>
            </label>
        </div>
    </div>
</div>
<div class="form-group row">
    <div class="col-sm-8 col-sm-offset-1">
        <div class="form-check">
            <input
                name="enabled2FA"
                [formControl]="preferencesForm().get('emailNewMessages')"
                class="form-check-input mr-2"
                type="checkbox" />
            <label class="form-check-label">
                New messages received
                <span
                    class="ml-2"
                    kno2Popover
                    [attr.data-content]="emailNewMessagesTooltip">
                    <i class="fa fa-info-circle box-info"></i>
                </span>
            </label>
        </div>
    </div>
</div>
@if (isAdministrator()) {
    <div class="form-group row">
        <div class="col-sm-8 col-sm-offset-1">
            <div class="form-check">
                <input
                    name="enabled2FA"
                    [formControl]="preferencesForm().get('emailUnassignedMessages')"
                    class="form-check-input mr-2"
                    type="checkbox" />
                <label class="form-check-label">
                    Unassigned messages (Administrators only)
                    <span
                        class="ml-2"
                        kno2Popover
                        [attr.data-content]="emailUnassignedMessagesTooltip">
                        <i class="fa fa-info-circle box-info"></i>
                    </span>
                </label>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-sm-8 col-sm-offset-1">
            <div class="form-check">
                <input
                    name="enabled2FA"
                    [formControl]="preferencesForm().get('emailUnprocessedMessages')"
                    class="form-check-input mr-2"
                    type="checkbox" />
                <label class="form-check-label">
                    Unprocessed messages (Administrators only)
                    <span
                        class="ml-2"
                        kno2Popover
                        [attr.data-content]="emailUnprocessedMessagesTooltip">
                        <i class="fa fa-info-circle box-info"></i>
                    </span>
                </label>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-sm-8 col-sm-offset-1">
            <div class="form-check">
                <input
                    name="enabled2FA"
                    [formControl]="preferencesForm().get('emailFailedMessagesDigest')"
                    class="form-check-input mr-2"
                    type="checkbox" />
                <label class="form-check-label">
                    Failed messages digest (Administrators only)
                    <span
                        class="ml-2"
                        kno2Popover
                        [attr.data-content]="emailFailedMessagesTooltip">
                        <i class="fa fa-info-circle box-info"></i>
                    </span>
                </label>
            </div>
        </div>
    </div>
}

<div class="form-group row">
    <span class="col-sm-8 col-sm-offset-1">Notice: Email Notification Settings could take up to 36 hours to fully propagate through our system</span>
</div>
