import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, firstValueFrom } from "rxjs";

export interface ChangePasswordRequest {
    oldPassword: string;
    password: string;
    confirmPassword: string;
}

@Injectable({
    providedIn: "root"
})
export class AccountService {
    private httpClient = inject(HttpClient);

    public changeOrganization = (orgId: string): Promise<string | null> => firstValueFrom(this.changeOrganization$(orgId));
    public changePassword = (password: { oldPassword: string; password: string; confirmPassword: string }): Promise<void> =>
        firstValueFrom(this.changePassword$(password));

    private changeOrganization$(orgId: string): Observable<string | null> {
        return this.httpClient.post<string | null>("/api/account/change", { orgId });
    }

    private changePassword$(request: ChangePasswordRequest): Observable<void> {
        // TODO: Test me
        // TODO: Delete old version
        return this.httpClient.put<void>("/api/account/password", request);
    }
}
