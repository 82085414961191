<div
    class="alert notification-alert alert-dismissible fade show"
    [class]="'alert-' + type()"
    role="alert">
    @if (type() === "danger") {
        <strong>Oh no!</strong>
    }
    {{ title() }}

    @if (messages().length) {
        <div class="messages">
            @for (message of messages(); track $index) {
                <span class="message">{{ message }}</span>
            }
        </div>
    }
    <button
        type="button"
        ng-show="notification.showClose"
        class="close"
        (click)="dismiss.emit()">
        &times;
    </button>
</div>
