import { inject, Injectable } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationDialogComponent } from "./confirmation-dialog.component";

@Injectable({
    providedIn: "root"
})
export class ConfirmationDialogService {
    private modalService = inject(NgbModal);

    public open(options: { bodyText?: string; okText?: string; cancelText?: string; windowClass?: string }): NgbModalRef {
        const modal = this.modalService.open(ConfirmationDialogComponent, {
            windowClass: "confirm-modal"
        });

        if (options.bodyText) modal.componentInstance.bodyText = options.bodyText;
        if (options.okText) modal.componentInstance.okText = options.okText;
        if (options.cancelText) modal.componentInstance.cancelText = options.cancelText;

        return modal;
    }
}
