import { Component, inject, input } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from "@angular/forms";
import { UpgradeModule } from "@angular/upgrade/static";
import { AccountService, ChangePasswordRequest } from "@kno2/data-access/account";
import { PopoverDirective } from "@kno2/shared/directives";
import { FormInputComponent, NotificationAlertComponent } from "@kno2/shared/ui";

@Component({
    selector: "kno2-profile-edit",
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, FormInputComponent, NotificationAlertComponent, PopoverDirective],
    templateUrl: "./profile-edit.component.html",
    styles: ``
})
export class ProfileEditComponent {
    private readonly accountService = inject(AccountService);
    private readonly upgrade = inject(UpgradeModule);
    private readonly notificationService = this.upgrade.$injector.get("NotificationService");

    public parentForm = input.required<FormGroup>();
    public mfaRequired = input.required<boolean>();
    public changingPassword = false;

    protected error: string;
    protected errorMessages: string[] = [];

    protected passwordForm = new FormGroup(
        {
            oldPassword: new FormControl<string>(null, Validators.required),
            password: new FormControl<string>(null, Validators.required),
            confirmPassword: new FormControl<string>(null, Validators.required)
        },
        {
            updateOn: "submit",
            validators: [this.validatePasswordMatch]
        }
    );
    protected passwordFormSubmitted = false;

    protected mfaTooltip = `
        Two-Factor Authentication adds another level of security to your account. This uses two different authentication factors, something you know and your \
        phone. If your password was stolen, your account would not be able to be accessed unless your phone had also been stolen. <br /><br />\
        <strong>To enable:</strong><ol><li>Enter your password</li><li>Next a code will be sent to your phone via phone call or text</li>\
        <li>Enter the code into the website</li></ol>\
        Upon entering the correct code, you will be granted access to your account.`;
    protected mfaEnforcedTooltip = `Two-Factor Authentication has been enforced for this Organization by your System Administrator.`;

    public async changePassword(): Promise<void> {
        try {
            this.passwordFormSubmitted = true;

            if (this.passwordForm.invalid) return;

            await this.accountService.changePassword(<ChangePasswordRequest>this.passwordForm.value);
            this.notificationService.success("Password changed successfully.");
            this.resetChangePasswordForm();
        } catch (err) {
            const { error } = err;
            this.error = error.message;
            this.errorMessages = Object.values(error.modelState);
        }
    }

    public validatePasswordMatch(control: AbstractControl): ValidationErrors | null {
        const password = control.get("password").value;
        const confirmPassword = control.get("confirmPassword").value;
        return password !== confirmPassword ? { passwordMatch: true } : null;
    }

    private resetChangePasswordForm(): void {
        this.passwordForm.reset();
        this.changingPassword = false;
        this.passwordFormSubmitted = false;
        this.error = null;
        this.errorMessages = [];
    }
}
