import { inject, Injectable } from "@angular/core";
import { UpgradeModule } from "@angular/upgrade/static";
import { AuthService as Auth0Service, AuthorizationParams } from "@auth0/auth0-angular";
import { DateTime } from "luxon";
import { firstValueFrom } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    private readonly auth0Service = inject(Auth0Service);
    private readonly upgrade = inject(UpgradeModule);
    private readonly localStorageFactory = this.upgrade.$injector.get("LocalStorageFactory");
    private readonly appConfig = this.upgrade.$injector.get("appConfig");

    private _accessToken: string;

    public get accessToken(): string {
        return this._accessToken;
    }

    private _connection: string;

    public get connection(): string {
        return this._connection;
    }

    public async login(params?: AuthorizationParams): Promise<void> {
        if (params?.connection) this._connection = params.connection;
        return firstValueFrom(this.auth0Service.loginWithRedirect({ authorizationParams: params }));
    }

    public async renewTokens(): Promise<void> {
        this._accessToken = await firstValueFrom(this.auth0Service.getAccessTokenSilently());
        this.scheduleRenewTokens();
    }

    public scheduleRenewTokens(): void {
        const accessTokenParts = this.accessToken.split(".")[1];
        const decodedAccessToken = JSON.parse(atob(accessTokenParts));
        const scheduledTime = DateTime.fromSeconds(decodedAccessToken.exp);
        const duration = Math.abs(scheduledTime.minus({ minutes: 1 }).diffNow("milliseconds").milliseconds);

        setTimeout(() => this.renewTokens(), duration);
    }

    public isAuthenticated(): Promise<boolean> {
        return firstValueFrom(this.auth0Service.isAuthenticated$);
    }

    public isSingleSignOnUser(profile): boolean {
        return profile.authStrategy !== "usernamepassword" || !profile.authStrategy;
    }

    public async logout(): Promise<void> {
        const profileData = this.localStorageFactory.get("ProfileData") || {};
        const logoutUrl = this.isSingleSignOnUser(profileData) ? `${this.appConfig.appUrl}/account/logout` : this.appConfig.appUrl;

        this.localStorageFactory.remove("features");
        this.localStorageFactory.remove("redirectUrl");
        this.localStorageFactory.remove("ProfileData");
        this._accessToken = "";

        return firstValueFrom(
            this.auth0Service.logout({
                logoutParams: {
                    returnTo: logoutUrl
                }
            })
        );
    }
}
